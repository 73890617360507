.social-container{
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
    width: 100%;
    gap: 20px;
    margin-left: 7%;
}
.social-image{
    width: 6rem;
    border-radius: 20px;
}
#youtube{
    width: 7rem;
}
.social-link{
    display: flex;
}
#gig-salad{
    margin-top: 10px;
}
@media only screen and (max-width: 768px) {
    .social-container{
        display: flex;
        flex-direction: column;
    }
}