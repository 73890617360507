.show-item-wrapper{
    display: flex;
    align-items: center;
    width: 85%;
    margin-bottom: 30px;
    justify-content: space-between;
}
.date-section {
    font-family: 'Roboto', sans-serif; 
    font-size: 22px; 
    display: flex;
    flex-direction: column; 
    align-items: center; 
    gap: 8px; 
}
.month-day-text {
    color: #fff; 
    font-weight: bold;
    text-transform: uppercase;
}
.time-text {
    color: #ff0000; 
}
.maps-link{
    text-decoration: none;   
    cursor: pointer; 
}
.location {
    display: flex;
    align-items: center;
    font-size: 45px;
    color: #fff;
    text-shadow: 3px 3px 3px black;
    transition: color 0.3s ease; 
    font-family: "Francois One", sans-serif;
}
.location:hover {
    color: #ff0000; 
}
@media only screen and (max-width: 768px) {
    .show-item-wrapper{
        width: 100%;
        flex-direction: column-reverse;
    }
    .location{
        font-size: 32px;
        margin-bottom: 18px;
        text-align: center;
    }
    .date-section{
        font-size: 24px;
    }
}